import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60')
];

export const server_loads = [0,13,2,9,3,6];

export const dictionary = {
		"/": [~14],
		"/admin": [57,[13]],
		"/admin/crawl": [~58,[13]],
		"/admin/crawl/[job]": [~59,[13]],
		"/admin/waitlist": [~60,[13]],
		"/(public)/auth/fail": [52,[12]],
		"/(public)/auth/verify": [53,[12]],
		"/(public)/contact": [54,[12]],
		"/(public)/login": [~55,[12]],
		"/(public)/logout": [56,[12]],
		"/(protected)/me": [~42,[2,9]],
		"/(protected)/me/invite": [~43,[2,9]],
		"/(protected)/me/invite/[inviteId]": [~44,[2,9]],
		"/(protected)/me/organisations": [~45,[2,9]],
		"/(protected)/me/organisations/[orga]": [~46,[2,9,10]],
		"/(protected)/me/organisations/[orga]/billing": [~47,[2,9,10]],
		"/(protected)/me/organisations/[orga]/collaborators": [~48,[2,9,10]],
		"/(protected)/me/settings": [49,[2,9,11]],
		"/(protected)/me/settings/appearance": [50,[2,9,11]],
		"/(protected)/me/settings/notifications": [~51,[2,9,11]],
		"/(protected)/[agent]": [~15,[2,3],[,4]],
		"/(protected)/[agent]/audiences": [~16,[2,3],[,4]],
		"/(protected)/[agent]/cohorts": [~17,[2,3],[,4]],
		"/(protected)/[agent]/cohorts/[id]": [~18,[2,3],[,4]],
		"/(protected)/[agent]/goals": [~19,[2,3],[,4]],
		"/(protected)/[agent]/keywords": [~20,[2,3],[,4]],
		"/(protected)/[agent]/migrate": [~21],
		"/(protected)/[agent]/pages": [~22,[2,3],[,4]],
		"/(protected)/[agent]/pages/[id]": [~23,[2,3],[,4]],
		"/(protected)/[agent]/personas": [~24,[2,3],[,4]],
		"/(protected)/[agent]/settings": [25,[2,3,5],[,4]],
		"/(protected)/[agent]/settings/appearance": [26,[2,3,5],[,4]],
		"/(protected)/[agent]/settings/billing": [~27,[2,3,5,6],[,4]],
		"/(protected)/[agent]/settings/billing/change": [~28,[2,3,5,6],[,4]],
		"/(protected)/[agent]/settings/billing/success": [~29,[2,3,5,6],[,4]],
		"/(protected)/[agent]/settings/collaborators": [~30,[2,3,5],[,4]],
		"/(protected)/[agent]/settings/danger": [~31,[2,3,5],[,4]],
		"/(protected)/[agent]/settings/guides": [~32,[2,3,5],[,4]],
		"/(protected)/[agent]/settings/notifications": [~33,[2,3,5],[,4]],
		"/(protected)/[agent]/settings/setup": [~34,[2,3,5],[,4]],
		"/(protected)/[agent]/strategies": [~35,[2,3],[,4]],
		"/(protected)/[agent]/tests": [~36,[2,3],[,4]],
		"/(protected)/[agent]/tests/[id]": [~37,[2,3],[,4]],
		"/(protected)/[agent]/tests/[id]/edit": [~38],
		"/(protected)/[agent]/tests/[id]/goals": [~39,[2,3],[,4]],
		"/(protected)/[agent]/welcome": [~40,[2,3],[,4]],
		"/(protected)/[agent]/welcome/[step]": [~41,[2,3,8],[,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';